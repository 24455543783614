<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    :class="{ hidden: this.showEditPriceModal == false }"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-lg w-full sm:align-middle sm:my-8"
      >
        <form @submit.prevent="submit">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div>
              <div class="mt-3 sm:mt-0 sm:ml-4 text-left">
                <div class="mt-2">
                  <div class="w-full mt-3">
                    <label
                      for="current-price"
                      class="block text-sm font-medium text-gray-700"
                      >Harga Sekarang</label
                    >
                    <input
                      type="text"
                      name="current-price"
                      id="current-price"
                      autocomplete="off"
                      class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      :value="this.currentPrice"
                      disabled
                    />
                  </div>

                  <div class="w-full mt-3">
                    <label
                      for="price"
                      class="block text-sm font-medium text-gray-700"
                      >Harga Baru</label
                    >
                    <input
                      v-model="form.new_price"
                      type="text"
                      name="price"
                      id="price"
                      autocomplete="off"
                      class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                    <span
                      class="text-red-500 text-sm block mt-1"
                      v-if="errors.new_price"
                    >
                      {{ errors.new_price[0] }}
                    </span>
                    <span
                      class="text-red-500 text-sm block mt-1"
                      v-if="errors.message"
                    >
                      {{ errors.message[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="submit"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <div v-if="loading" class="btn-spinner mt-1 mr-2" />
              Save
            </button>
            <button
              @click="closeEditPriceModal()"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EditPriceModal",
  props: ["currentPrice", "selectedDetailId"],
  computed: {
    ...mapState("transactionsHistory", {
      showEditPriceModal: (state) => state.showEditPriceModal,
      form: (state) => state.form,
      errors: (state) => state.errors,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    closeEditPriceModal() {
      this.$store.dispatch("transactionsHistory/openEditPriceModal", false);
    },
    submit() {
      this.$store.dispatch("transactionsHistory/changePrice", {
        form_data: this.form,
        selected_detail_id: this.selectedDetailId,
      });
    },
  },
};
</script>
